// https://github.com/leighhalliday/google-maps-threejs/blob/main/pages/markers.js
// https://developers.google.com/maps/documentation/javascript/reference/advanced-markers
// https://developers.google.com/maps/documentation/javascript/advanced-markers/start

import styled from 'styled-components';
import { useJsApiLoader } from '@react-google-maps/api';

import { Board, Job } from '../../../generated/types';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { useEffect, useId } from 'react';
import { Colors } from '../../../styles/colors';

// const parser = new DOMParser();
// const pinSvgString = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
// <svg
//    xmlns:dc="http://purl.org/dc/elements/1.1/"
//    xmlns:cc="http://creativecommons.org/ns#"
//    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
//    xmlns:svg="http://www.w3.org/2000/svg"
//    xmlns="http://www.w3.org/2000/svg"
//    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
//    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
//    width="56"
//    height="56"
//    viewBox="0 0 56 56"
//    fill="none"
//    version="1.1"
//    id="svg857"
//    sodipodi:docname="tiny-house.svg"
//    inkscape:version="1.0 (4035a4f, 2020-05-01)">
//   <metadata
//      id="metadata863">
//     <rdf:RDF>
//       <cc:Work
//          rdf:about="">
//         <dc:format>image/svg+xml</dc:format>
//         <dc:type
//            rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
//       </cc:Work>
//     </rdf:RDF>
//   </metadata>
//   <defs
//      id="defs861" />
//   <sodipodi:namedview
//      inkscape:document-rotation="0"
//      pagecolor="#ffffff"
//      bordercolor="#666666"
//      borderopacity="1"
//      objecttolerance="10"
//      gridtolerance="10"
//      guidetolerance="10"
//      inkscape:pageopacity="0"
//      inkscape:pageshadow="2"
//      inkscape:window-width="1515"
//      inkscape:window-height="849"
//      id="namedview859"
//      showgrid="false"
//      inkscape:zoom="12.714286"
//      inkscape:cx="25.522472"
//      inkscape:cy="36.966292"
//      inkscape:window-x="0"
//      inkscape:window-y="25"
//      inkscape:window-maximized="0"
//      inkscape:current-layer="svg857" />
//   <rect
//      style="stroke-width:0.527013"
//      y="12.269663"
//      x="14.157303"
//      width="27.685394"
//      height="31.460674"
//      rx="13.842697"
//      fill="#7837ff"
//      id="rect833" />
//   <path
//      d="m 36.932247,24.703315 -0.992422,0.366516"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path835" />
//   <path
//      d="m 20.060323,30.932641 -0.992511,0.366404"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path837" />
//   <path
//      d="M 27.999951,38.676742 V 37.490618"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path839" />
//   <path
//      d="m 27.999951,17.325843 v 1.186123"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path841" />
//   <path
//      d="M 33.516463,36.629045 32.903578,35.670337"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path843" />
//   <path
//      d="m 22.483636,19.373146 0.612885,0.958652"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path845" />
//   <path
//      d="m 19.067812,24.703315 0.992511,0.366516"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path847" />
//   <path
//      d="m 36.932297,31.299101 -0.992472,-0.36646"
//      stroke="#ffffff"
//      stroke-width="1.31753"
//      stroke-linecap="round"
//      stroke-linejoin="round"
//      id="path849" />
//   <path
//      id="rect2879"
//      style="fill:#000000;stroke-width:0.362533"
//      d="m 28.019442,21.803598 -6.563011,5.777137 h 2.060502 v 5.511008 h 9.005017 v -5.511008 h 2.060502 z" />
// </svg>
// `;

const JobsMap: React.FC<any> = ({ board, jobs }: JobsMapProps) => {
  const mapId = useId();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API,
  });

  const locations = jobs
    .filter((location) => location.companyLocationLatitude && location.companyLocationLongitude)
    .map((job) => ({
      lat: job.companyLocationLatitude,
      lng: job.companyLocationLongitude,
      ...job,
    })) as position[];

  if (!board.locationLatitude || !board.locationLongitude) {
    return (
      <InfoAlert>
        Update your home location on your Jobs Board to calculate the distance to your job opportunities accurately.
      </InfoAlert>
    );
  }

  const center = {
    lat: board.locationLatitude,
    lng: board.locationLongitude,
  };

  useEffect(() => {
    if (!isLoaded) return;

    async function initMap() {
      const { Map, InfoWindow } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;

      // Create an info window to share between markers.
      const infoWindow = new InfoWindow();

      const pinScaled = new PinElement({
        scale: 0.7,
        background: Colors.Primary,
        borderColor: Colors.ContrastDarkest,
        glyphColor: Colors.ContrastDarkest,
      });

      const map = new Map(document.getElementById('map') as HTMLElement, {
        center,
        zoom: 9,
        mapId,
      });

      // Home marker
      const homeMarker = new AdvancedMarkerElement({
        map,
        position: center,
        content: pinScaled.element,
      });
      homeMarker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(`
          <div>
            <h3>HOME</h3>
          </div>
        `);
        infoWindow.open(homeMarker.map, homeMarker);
      });

      locations.forEach((location, i) => {
        const center = { lat: location.lat, lng: location.lng };
        const pin = new PinElement({
          // glyph: `${i + 1}`,
          background: Colors.ContrastDarkest,
          borderColor: Colors.Primary,
          glyphColor: Colors.Primary,
        });

        const marker = new AdvancedMarkerElement({
          map,
          position: center,
          title: `${location.jobTitle}`,
          content: pin.element,
        });
        marker.addListener('click', () => {
          infoWindow.close();
          infoWindow.setContent(`
            <div>
              <h3>${location.jobTitle}</h3>
              <p>${location.company}</p>
            </div>
          `);
          infoWindow.open(marker.map, marker);
        });
      });
    }

    initMap();
  }, [isLoaded]);

  return isLoaded ? (
    <>
      <MapWrapper>
        <div id="map" style={{ height: '100%' }}></div>
      </MapWrapper>
      {/* <pre>{JSON.stringify(locations, null, 2)}</pre> */}
    </>
  ) : (
    <></>
  );
};

export default JobsMap;

const MapWrapper = styled.div`
  height: 70vh;
  border: 1px solid ${Colors.Primary};
`;

type JobsMapProps = {
  board: Board;
  jobs: Job[];
};

interface position extends Job {
  lat: number;
  lng: number;
}
